import { template as template_3f198b09bb3a4c2587f4d8be25a4c8fc } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_3f198b09bb3a4c2587f4d8be25a4c8fc(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
