import { template as template_ab1ae5b5fa354259aa62bb9aa206fde8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ab1ae5b5fa354259aa62bb9aa206fde8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
